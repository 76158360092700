/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Chart, ChartData, ChartOptions, ChartType, registerables, ScriptableContext, TooltipItem, Scale, CoreScaleOptions } from "chart.js";

import { Controller } from "@hotwired/stimulus";
import "chartjs-plugin-dragdata/dist/chartjs-plugin-dragdatas.esm.js"
import zoomPlugin from "chartjs-plugin-zoom";
import datalabelsPlugin, { Context } from "chartjs-plugin-datalabels";

type DragChartOptions = ChartOptions &
  {
    plugins: {
      dragData: {
        enabled: boolean;
        round?: number;
        showTooltip?: boolean;
        onDragStart: (e: MouseEvent, datasetIndex: number, index: number, value: number) => false | undefined;
        onDragEnd: (e: MouseEvent, datasetIndex: number, index: number, value: number) => void;
      };
      zoom: {
        limits: {
          yLeft: {
            min: number;
            max: number;
          };
        };
        zoom: {
          onZoom: (context: { chart: Chart }) => void;
        };
      };
    };
  };

type CustomLineOptions = {
  line: number;
  direction?: "horizontal" | "vertical";
  color?: string;
  hidden?: boolean;
  cancel?: (chart: CustomLinesChartOptions | Chart, line: number) => boolean;
  xAxisID?: string;
  yAxisID?: string;
  xStart?: (chart: CustomLinesChartOptions | Chart, xAxis: Scale<CoreScaleOptions>, yAxis: Scale<CoreScaleOptions>) => number;
  yStart?: (chart: CustomLinesChartOptions | Chart, xAxis: Scale<CoreScaleOptions>, yAxis: Scale<CoreScaleOptions>) => number;
  xEnd?: (chart: CustomLinesChartOptions | Chart, xAxis: Scale<CoreScaleOptions>, yAxis: Scale<CoreScaleOptions>) => number;
  yEnd?: (chart: CustomLinesChartOptions | Chart, xAxis: Scale<CoreScaleOptions>, yAxis: Scale<CoreScaleOptions>) => number;
};

type CustomLinesChartOptions = ChartOptions & {
  plugins: {
    customLines: CustomLineOptions[];
  };
};

export default class extends Controller {
  static targets = ["chart", "data", "options", "requiredDrivers"];

  declare chartTarget: HTMLElement | null;
  declare dataTarget: HTMLElement | null;
  declare optionsTarget: HTMLElement | null;
  declare requiredDriversTarget: HTMLInputElement | null;

  chartData: ChartData | null = null;
  chartOptions: DragChartOptions | CustomLinesChartOptions | ChartOptions | null = null;
  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  chart!: Chart;
  graphType = "line";

  connect(): void {
    Chart.register(...registerables);

    if (this.dataTarget != null) {
      this.chartData = JSON.parse(this.dataTarget.innerText);
    }

    if (this.optionsTarget != null) {
      this.chartOptions = JSON.parse(this.optionsTarget!.innerText);

      const chartName = this.data.get("name");
      if (chartName == "driverOrdersChart") {
        Chart.register(zoomPlugin);
        this.setDriverOrdersChartOptions();
      }
      else if (chartName == "shiftReportChart") {
        Chart.register(datalabelsPlugin);
        Chart.register(this.customPlugin());
        this.setShiftReportChartOptions();
      }
    }

    if (this.chartTarget) {
      this.canvas = this.chartTarget.querySelector("canvas");
      if (!this.canvas) {
        throw "Couldn't find the canvas";
      }
      this.canvasContext = this.canvas.getContext("2d");
    }

    this.graphType = this.data.get("type") || "line";

    this.initialiseChart();
  }

  initialiseChart(): void {
    if (this.graphType == "pie") {
      this.chartOptions!.plugins!.tooltip = {
        callbacks: {
          label: function (context) {
            return context.dataset.data[context.dataIndex] + "%"
          }
        }
      }
    } else if (this.graphType == "bar") {
      const aboveColor = this.data.get("backgroundColorAbove");
      const belowColor = this.data.get("backgroundColorBelow");

      if (aboveColor && belowColor) {
        this.chartData!.datasets![0].backgroundColor = (ctx: ScriptableContext<"bar">) => {
          return ctx.dataset!.data![ctx.dataIndex] > 0 ? aboveColor : belowColor;
        };
      }
    }

    this.chart = new Chart(this.canvasContext!, {
      type: this.graphType as ChartType,
      data: this.chartData!,
      options: this.chartOptions!
    });
  }

  customPlugin() {
    return {
      id: "customLines",
      afterDraw: (chart: Chart) => {
        const lines: CustomLineOptions[] = (chart.config.options as CustomLinesChartOptions).plugins!.customLines;

        lines.forEach(options => {
          let line = options.line;

          if (options.hidden || isNaN(line) || options.cancel?.(chart, line))
            return;

          const xID = options.xAxisID || "x";
          const yID = options.yAxisID || "y";
          const xAxis = chart.scales[xID];
          const yAxis = chart.scales[yID];

          let xStart;
          let yStart;
          let xEnd;
          let yEnd;

          // Vertical
          if (options.direction == "vertical") {
            if (line < xAxis.min || line > xAxis.max) return;

            yStart = options.yStart?.(chart, xAxis, yAxis) || yAxis.top;
            yEnd = options.yEnd?.(chart, xAxis, yAxis) || yAxis.bottom;

            line = (line - xAxis.min) / (xAxis.max - xAxis.min);
            line = (line * xAxis.width) + xAxis.left;
            xStart = xEnd = line;
          }
          // Horizontal
          else {
            if (line < yAxis.min || line > yAxis.max) return;

            xStart = options.xStart?.(chart, xAxis, yAxis) || xAxis.left;
            xEnd = options.xEnd?.(chart, xAxis, yAxis) || xAxis.right;

            line = (line - yAxis.min) / (yAxis.max - yAxis.min);
            line = yAxis.height - (line * yAxis.height) + yAxis.top;
            yStart = yEnd = line;
          }

          const ctx = chart.ctx;
          ctx.strokeStyle = options.color || "#FF0000";
          ctx.beginPath();
          ctx.moveTo(xStart, yStart);
          ctx.lineTo(xEnd, yEnd);
          ctx.stroke();
        })
      }
    }
  }

  setDriverOrdersChartOptions(): void {
    const selectableDatasetIndex = parseInt(this.data.get("selectableDatasetIndex") || "");
    const driverToOrderRatio = parseInt(this.data.get("driverToOrderRatio") || "");

    if (!driverToOrderRatio) {
      throw "Missing driverToOrderRatio parameter"
    }

    this.chartOptions!.scales!.x!["ticks"] = {
      callback: function (value) {
        return (this.getLabelForValue(value as number)).trim().split(/\s+/)[1];
      }
    }

    this.chartOptions!["plugins"] = {
      ...{
        dragData: {
          enabled: true,
          round: 0,
          onDragStart: (_e, datasetIndex) => {
            if (!selectableDatasetIndex || datasetIndex !== selectableDatasetIndex) {
              return false;
            }
          },
          onDragEnd: (_e, datasetIndex) => {
            const data = this.chart.data.datasets[datasetIndex];
            const keys = this.chart.data.labels as string[]
            const values = data.data as number[]
            const result: { [key: string]: number } = {};

            keys!.forEach((key: string, i) => result[key] = values[i]);

            this.requiredDriversTarget!.value = JSON.stringify(result);
          },
        },
        zoom: {
        limits: {
          yLeft: {
            min: 0,
            max: 300,
            minRange: 5
          },
        },
        zoom: {
          mode: 'y',
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          onZoom: (context) => {
            // Enforce 0 as the min when zooming
            context.chart!.config!.options!.scales!.yLeft!.min = 0;
            context.chart!.config!.options!.scales!.yRight!.min = 0;

            // Enforce driver to order ratio on zoom
            const driverMax = context.chart?.config?.options?.scales?.yLeft?.max;
            const orderMax = (driverMax as number) * driverToOrderRatio;

            context.chart!.config!.options!.scales!.yRight!.max = orderMax;
            context.chart!.update('none')
          },
        }
      }
      },
      ...this.chartOptions!["plugins"],
    }
  }

  setShiftReportChartOptions(): void {
    //----- Data & metadata
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { hoursOfDay, areaName, shiftDate } = (this.chartData! as any).metadata;
    const orders = this.chartData!.datasets[0].data as number[];
    const lateOrders = this.chartData!.datasets[1].data as number[];
    const drivers = this.chartData!.datasets[2].data as number[];

    //----- Vars for customLines current time
    const startTimeArr: number[] = hoursOfDay[0].replace(/[ap]m/, "").split(":").map((s: string) => +s);
    const startHour: number = startTimeArr[0] == 12 && hoursOfDay[0].includes("am") ? 0 : startTimeArr[0];
    const startMinute: number = startTimeArr[1];
    // Current time
    const now: Date = new Date();
    // Offset for start time
    const startTimeInMinutes: number = (hoursOfDay[0].includes("am") ? startHour : startHour + 12) * 60 + startMinute;
    // Boolean to display the line only on the current day
    const isShiftReportForToday: boolean = shiftDate == now.toISOString().split("T")[0];

    const totalOrders: number = orders.reduce((sum, n) => sum! + n!, 0);
    const totalLates: number = lateOrders.reduce((sum, n) => sum! + n!, 0);
    const latePercentage: string = (totalLates / totalOrders * 100).toFixed(2);
    const lateString: string = totalLates ? ` - ${totalLates} lates (${latePercentage}%)` : "";
    // Chart title
    const chartTitle = `${totalOrders} orders${lateString} ── ${areaName} (${shiftDate})`;

    //----- Colors for order bars, as a percentage of the highest orders value
    // 80-100% is red, 60-79% is orange, 0-59% & values under 5 are green
    const threshold80: number = Math.max(...orders) * 0.8;
    const threshold60: number = Math.max(...orders) * 0.6;
    const borderColors: string[] = orders.map(n => {
      // Red
      if (n >= 5 && n >= threshold80)
        return "#FD2407";
      // Orange
      else if (n >= 5 && n >= threshold60)
        return "#EF6624";
      // Green
      return "#50CD1D";
    })
    // Add transparency to the main background fill color
    const backgroundColors: string[] = borderColors.map(s => s + "A0");

    this.chartData!.datasets = [
      {
        //----- Orders
        ...this.chartData!.datasets[0],
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        tooltip: {
          callbacks: {
            // Merge the orders & late orders tooltip
            label: (data: TooltipItem<"bar">) => {
              const label = [ "Orders: " + data.raw ];
              if (lateOrders[data.dataIndex])
                label.push("Lates: " + lateOrders[data.dataIndex]);
              return label;
            }
          }
        }
      }, {
        //----- Lates
        ...this.chartData!.datasets[1],
        tooltip: {
          callbacks: {
            // Merge the orders & late orders tooltip
            label: (data: TooltipItem<"bar">) => {
              if (data.raw == orders[data.dataIndex])
                return null;

              return [
                "Orders: " + orders[data.dataIndex],
                "Lates: " + data.raw
              ];
            },
            // Set the style for the colored squre in the tooltip
            labelColor: (data: TooltipItem<"bar">) => {
              return {
                backgroundColor: backgroundColors[data.dataIndex],
                borderColor: borderColors[data.dataIndex],
                borderWidth: 2
              };
            }
          }
        }
      }, {
        //----- Drivers
        ...this.chartData!.datasets[2],
        // Don't draw a circle if the data is the same as the previous & next values
        pointRadius: (data: ScriptableContext<"line">) => {
          if (data.raw == drivers[data.dataIndex - 1] && data.raw == drivers[data.dataIndex + 1])
            return 0;
          return 2;
        },
      }, {
        //----- Panic
        ...this.chartData!.datasets[3],
        // Ignore chart hover effects unless it's a key point
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pointRadius: (data: any) => data.raw?.key ? 2 : 0,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hitRadius: (data: any) => data.raw?.key ? 15 : 0,
        tooltip: {
          callbacks: {
            // Format the label to show the number of minutes of panic, & the admin who created the panic
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            label: (data: any) => {
              return [
                data.raw.y + " minutes",
                data.raw.name
              ];
            }
          }
        }
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any;

    this.chartOptions!.plugins = {
      //----- Title
      title: {
        display: true,
        text: chartTitle,
        color: "#141414"
      },
      //----- Legend
      legend: {
        labels: {
          color: "#141414",
          // Sort the panic legend to the end
          sort: (a, b) => {
            if (a.datasetIndex == 2)
              return 3;
            return a.datasetIndex! - b.datasetIndex!;
          },
          // Set legend styles
          generateLabels: (chart: Chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);

            // Orders
            labels[3].fillStyle = "#50CD1DA0";
            labels[3].strokeStyle = "#50CD1D";
            // Lates
            labels[0].fillStyle = "#FFFF4C80";
            labels[0].strokeStyle = "#D9D900A0";
            labels[0].lineWidth = 2;
            // Drivers
            labels[1].strokeStyle = "#000000A0";
            labels[1].lineWidth = 2;
            // Panic
            labels[2].strokeStyle = "#3BF9E7A0";
            labels[2].lineWidth = 2;

            return labels;
          }
        }
      },
      //----- Custom Lines
      customLines: [{
        // Show a red line at 60 minutes panic
        direction: "horizontal",
        line: 60,
        xAxisID: "xPanic",
        yAxisID: "yPanic",
        color: "#FF000060",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cancel: chart => (chart as any)._metasets[3].hidden
      }, {
        // Show a blue line at the current time, if the report is for the current day
        direction: "vertical",
        line: now.getHours() * 60 + now.getMinutes() - startTimeInMinutes,
        hidden: !isShiftReportForToday,
        xAxisID: "xPanic",
        yAxisID: "yPanic",
        color: "#0000FF80"
      }],
      //----- Tooltips
      tooltip: {
        // Ignore tooltips for panic unless it's a key point
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter: (data: any) => data.datasetIndex == 3 ? data.raw.key : true,
        callbacks: {
          // Change the panic tooltip title to the time, rather than the amount
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: (data: any) => data[0]?.datasetIndex == 3 ? data[0].raw.time : data[0]?.label
        }
      },
      //----- Datalabels
      datalabels: {
        align: "start",
        anchor: "end",
        color: "#000000",
        font: {
          weight: 700
        },
        // Don't draw labels for values of 0
        formatter: value => value || null,
        // If the value is 1, or less than 10% of the scale height, draw the label above the bar, otherwise draw it inside the bar
        offset: (context: Context) => {
          const value = context.dataset.data[context.dataIndex];
          if (typeof value == "number" && (value == 1 || value < context.chart.scales.y.max * 0.1))
            return -20;
          return 4;
        }
      }
    };

    this.chartOptions!.scales!.xOrders = {
      ...this.chartOptions!.scales!.xOrders,
      // Only draw labels on the hour, & remove minutes from the display
      afterTickToLabelConversion: data => {
        data.ticks?.forEach((tick, i) => {
          if (typeof tick.label == "string") {
            if (tick.label.includes(":00") && i < data.ticks.length - 1)
              tick.label = tick.label.replace(":00", "");
            else
              tick.label = "";
          }
        });
      }
    };
  }
}
/* eslint-enable  @typescript-eslint/no-non-null-assertion */

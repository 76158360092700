import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "fullName", "firstName", "lastName", "invoicingName" ];
  declare fullNameTarget: HTMLInputElement;
  declare firstNameTarget: HTMLInputElement;
  declare lastNameTarget: HTMLInputElement;
  declare invoicingNameTarget: HTMLInputElement;

  connect() {
    this.updatePlaceholders();
  }

  updatePlaceholders() {
    const nameParts = this.getNameParts();

    this.firstNameTarget.setAttribute("placeholder", nameParts.firstName);
    this.lastNameTarget.setAttribute("placeholder", nameParts.lastName);
    this.invoicingNameTarget.setAttribute("placeholder", nameParts.invoicingName);
  }

  updateFields() {
    const nameParts = this.getNameParts();

    this.firstNameTarget.value = nameParts.firstName;
    this.lastNameTarget.value = nameParts.lastName;
    this.invoicingNameTarget.value = nameParts.invoicingName;
  }

  getNameParts() {
    const name = this.fullNameTarget?.value;
    const nameParts = name?.match(/^(\S+)(?: .*?(\S+))? *$/);

    return {
      firstName: nameParts?.[1] || "",
      lastName: nameParts?.[2] || "",
      invoicingName: name || ""
    };
  }
}

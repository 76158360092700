import { Message } from "./message";
import { DriverShiftStatus } from "../driver_shift_status";

export class Conversation {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  name: string;
  mobileNumber: string;
  internalNote: string;
  chatChannelName: string;
  driverShiftStatus: DriverShiftStatus;
  areaId: number;
  areaName: string;

  historyStatus: HistoryStatus = HistoryStatus.Unloaded;

  unreadMessages: number = 0;
  conversationElement: HTMLElement | null = null;
  isActiveConversation = false;

  messages: Message[] = [];

  constructor(
    id: number,
    uuid: string,
    fname: string,
    lname: string,
    name: string,
    mobileNumber: string,
    internalNote: string,
    chatChannelName: string,
    driverShiftStatus: DriverShiftStatus,
    areaId: number,
    areaName: string
  ) {
    this.id = id;
    this.uuid = uuid;
    this.firstName = fname;
    this.lastName = lname;
    this.name = name;
    this.mobileNumber = mobileNumber;
    this.internalNote = internalNote;
    this.chatChannelName = chatChannelName;
    this.driverShiftStatus = driverShiftStatus;
    this.areaId = areaId;
    this.areaName = areaName;
  }

  addMessage(message: Message, skipUnread: boolean) {
    if (this.messages.some((m) => message.messageID === m.messageID)) return;

    this.messages.push(message);

    this.messages.sort((a, b) => {
      // Messages that don't have a publishedAt aren't "sent"
      // So they shouldn't really ever be here, but because we
      // use the same model for messages that are sent and not
      // sent this property is optional. To handle this, I just
      // sort these to the bottom for now, while I figure out
      // how to fix up the modelling.
      if (a.sortingProperty() < b.sortingProperty()) return -1;
      else if (a.sortingProperty() > b.sortingProperty()) return 1;
      else return 0;
    });

    if (this.isActiveConversation == false && skipUnread != true) {
      this.unreadMessages += 1;
    }
  }

  sortKey() {
    if (this.unreadMessages > 0) {
      return `-1${this.name}`;
    } else {
      return this.name;
    }
  }
}

export enum HistoryStatus {
  Unloaded = "unloaded",
  Loading = "loading",
  Loaded = "loaded",
}
